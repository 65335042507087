import './styles.scss';

import { Loading } from '@nuvioo/core-ui';
import { useLogin, useTranslate } from '@refinedev/core';
import { Col, Layout, notification, Row } from 'antd';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LoginOnBehalf: React.FC = () => {
    const translate = useTranslate();
    const { search } = useLocation();
    const { mutate: login } = useLogin();
    const query = qs.parse(search, {
        ignoreQueryPrefix: true,
    });

    if (query && !query?.token) {
        notification.error({
            message: translate('login_on_behalf.token_not_exist'),
        });
    }

    useEffect(() => {
        const token = query?.token;
        if (token) {
            const redirectUrl = `${window.location.protocol}//${window.location.host}/dashboard`;
            login({
                data: {
                    token,
                },
                redirectUrl,
            });
        }
    }, []);

    return (
        <Layout className="login-on-behalf-container">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: '100vh',
                }}
            >
                <Col md={12}>
                    <Loading />
                </Col>
            </Row>
        </Layout>
    );
};
